import ExpandIcon from '@/components/atoms/Icon/svg/ExpandIcon'
import { LETTER_YEAR } from '@/constant/text'
import { siteSubmissionApi } from '@/ghgApi'
import { Submission } from '@/openapi'
import theme from '@/theme'
import { makeRows, RowProps } from '@/utils/inputHelper'
import useStore from '@/zustand/sotre'
import { makeStyles, Typography, withStyles } from '@material-ui/core'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import React, { useEffect, useState } from 'react'
import MonthTable from './MonthTable'

const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        backgroundColor: theme.colors.accordionBackground,
        boxShadow: 'none',
        padding: 0,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion)
const AccordionSummary = withStyles({
    root: {
        backgroundColor: 'rgba(0, 0, 0, .03)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        justifyContent: 'start',
        '&$expanded': {
            minHeight: 56,
        },
    },
    content: {
        width: 'fit-content',
        flexGrow: 0,
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expandIcon: {
        '& > span > svg': {
            width: 10,
            '& > path': {
                fill: '#000',
            },
        },
    },
})(MuiAccordionSummary)

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails)

const useStyle = makeStyles({
    container: {
        marginTop: 20,
    },
    year: { fontSize: 14, fontWeight: 600 },
    accordionDetail: {
        padding: 0,
    },
    accordionDisabled: {
        pointerEvents: 'none',
    },
    loading: {
        marginTop: 20,
    },
})

function MonthsData() {
    const { storeState, analysisState } = useStore()
    const [loading, setLoading] = useState(false)
    const [submission, setSubmission] = useState<Submission[]>()
    const [listYears, setListYears] = useState<{ year: number; data: RowProps[] }[]>([])

    const classes = useStyle()

    const generateRow = (year: number, startMonth: number, submissions: Submission[]) => {
        const firstCategory3 = Array.from({ length: 8 }, (_, i) => i + 3)
        const secondCategory3 = Array.from({ length: 7 }, (_, i) => i + 11)
        const result = makeRows(year, startMonth).map((row) => {
            const submission = submissions.find((s) => s.month == row.dateString)
            row.scope1 = { id: 0, status: submission?.categories.find((c) => c.id === 1)?.status || row.status }
            row.scope2 = { id: 1, status: submission?.categories.find((c) => c.id === 2)?.status || row.status }
            row.scope3_1 = {
                id: 2,
                status: submission?.categories.find((c) => firstCategory3.includes(c.id))?.status || row.status,
            }
            row.scope3_2 = {
                id: 2,
                status: submission?.categories.find((c) => secondCategory3.includes(c.id))?.status || row.status,
            }
            if (submission) row.status = submission.status
            return row
        })
        return result
    }

    useEffect(() => {
        const fetchData = async () => {
            if (storeState.selectedSite && storeState.organization?.startMonth) {
                setLoading(true)
                try {
                    const res = await siteSubmissionApi.getSiteSubmissions(storeState.selectedSite.id)
                    const submissions = res.data.submissions
                    setSubmission(submissions)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    console.warn('SiteApi.getSiteSubmissions:', err)
                }
            }
        }
        fetchData()
    }, [storeState.selectedSite])

    useEffect(() => {
        if (analysisState.dateSelector.options.years.length && storeState.organization?.startMonth && submission) {
            const data = analysisState.dateSelector.options.years.map((year) => {
                const subData: RowProps[] = generateRow(year, storeState.organization?.startMonth as number, submission)
                return { year, data: subData }
            })
            setListYears(data)
        }
    }, [analysisState.dateSelector.options.years, submission, storeState.organization?.startMonth])

    if (listYears.length) {
        return (
            <div className={classes.container}>
                {listYears.map((item) => (
                    <Accordion
                        key={item.year}
                        className={loading ? classes.accordionDisabled : ''}
                        defaultExpanded={item.year === analysisState.date.year}
                    >
                        <AccordionSummary
                            aria-controls={`panel${item}d-content`}
                            id={`panel${item}d-header`}
                            expandIcon={<ExpandIcon />}
                        >
                            <Typography className={classes.year}>
                                {item.year}
                                {LETTER_YEAR}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails classes={{ root: classes.accordionDetail }}>
                            {item.data.length ? <MonthTable data={item.data} fiscalYear={item.year} /> : 'loading'}
                        </AccordionDetails>
                    </Accordion>
                ))}
            </div>
        )
    } else {
        return <Typography className={classes.loading}>loading...</Typography>
    }
}

export default MonthsData
